import { Quill } from "vue2-editor";

var Blot = Quill.import('formats/image');

class ImageBlot extends Blot {
	static create(data) {
		const node = super.create(data);

		if(data.data) {
			Object.keys(data.data).forEach(key => {
				node.setAttribute(`data-${key}`, data.data[key]);
			})
		}

		node.setAttribute('src', data.src);
		return node;
	}

	static value(domNode) {
		const { src, custom } = domNode.dataset;
		return { src, custom };
	}
}

ImageBlot.blotName = 'imageBlot';
ImageBlot.className = 'image-blot';
ImageBlot.tagName = 'img';

Quill.register({ 'formats/imageBlot': ImageBlot });
