<template>
    <div class="card border mb-3" v-if="timers && timers.length && this.dataCount !== 0">
        <div class="card-body">
            <h4 class="mt-0 card-title">Потраченное время {{ title ? `на ${title}` : '' }}</h4>
            <vue-apex-charts :height="height" :options="options" :series="series"></vue-apex-charts>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import dayjs from '@/plugins/day'

import VueApexCharts from 'vue-apexcharts'

export default {
    name: 'TimerChart',
    props: ['timers', 'users', 'title'],
    data: () => {
        return {
            options: {},
            series: [],
            dataCount: 0,
            height: 0
        }
    },
    computed: {
        ...mapGetters({
			timePassedFormat: 'timer/timePassedFormat'
		}),
    },
    mounted() {
        this.compute()
    },
    methods: {
        compute() {
            let tsStart = new Date().getTime()/1000

            let userMap = {}
            this.users.forEach(user => userMap[user.id] = user)

            let users = {}, days = {}
            this.timers.forEach(timer => {
                let user = timer.user_id
                if(!users[user]) users[user] = {}

                //live timer
                if(timer.time_finish == null) {
                    //TODO!
                
                //finished timer
                } else {
                    let tsStart = dayjs(timer.time_start), 
                        tsFinish = dayjs(timer.time_finish)

                    
                    let dayStart = tsStart.format('DD.MM.YYYY'),
                        dayFinish = tsFinish.format('DD.MM.YYYY')

                    //timer was between days
                    if(dayStart != dayFinish) {
                        while(dayStart !== dayFinish) {
                            if(!days[dayStart]) days[dayStart] = 0
                            if(!users[user][dayStart]) users[user][dayStart] = 0

                            //counting till the end of day
                            let endOfTheDay = tsStart.endOf('day')
                            users[user][dayStart] += endOfTheDay.diff(tsStart, 'second') / 60 / 60

                            //go to next day
                            tsStart = endOfTheDay.add(1, 'minute').startOf('day')
                            dayStart = tsStart.format('DD.MM.YYYY')
                        }

                        if(!days[dayStart]) days[dayStart] = 0
                        if(!users[user][dayStart]) users[user][dayStart] = 0
                        users[user][dayStart] += tsFinish.diff(tsStart, 'second') / 60 / 60

                    //same day timer
                    } else {
                        if(!days[dayStart]) days[dayStart] = 0
                        if(!users[user][dayStart]) users[user][dayStart] = 0

                        users[user][dayStart] += tsFinish.diff(tsStart, 'second') / 60 / 60
                    }
                }
            })

            days = Object.keys(days)
            days.sort((a, b) => (dayjs(a, 'DD.MM.YYYY').isAfter(dayjs(b, 'DD.MM.YYYY')) ? -1 : 1))

            this.height = 60 + 60 * days.length * Object.keys(users).length

            this.options = {
                chart: {
                    type: 'bar',
                    toolbar: {
                        show: false,
                    },
                    height: this.height
                },
                dataLabels: {
                    enabled: true,
                    formatter: (value) => {
                        return this.timePassedFormat(value * 60 * 60, {hours: true, minutes: true})
                    },
                    textAnchor: 'middle',
                    style: {
                        colors: ['#000']
                    }
                },
                tooltip: {
                    enabled: false,
                },
                grid: {
                    show: false,
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                    }
                },
                xaxis: {
                    categories: days,
                    title: {
                        text: 'часов потрачено',
                        style: {
                            fontWeight: 200,
                        }
                    }
                },
                yaxis: {
                    
                }
            }

            let series = []
            Object.keys(users).forEach(user => {
                let userDays = users[user]
                
                let serie = []
                days.forEach(day => {
                    if(!userDays[day]) 
                        serie.push( 0 )
                    else {
                        this.dataCount++
                        serie.push( userDays[day] )
                    }
                        
                })
                series.push({
                    name: userMap[user] ? userMap[user].name : 'user?',
                    data: serie
                })
            })

            this.series = series

            console.log(`chart computed with ${ this.timers.length } timers in ${ (new Date().getTime()/1000 - tsStart).toFixed(2) } sec`)
        }
    },
    watch: {
        timers: function() {
            this.compute()
        }
    },
    components: {
        VueApexCharts
    }
}
</script>

<style>

</style>