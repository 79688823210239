<template>
    <p v-if="total">
        <span class="pr-3 text-nowrap mb-2 d-inline-block">
            <i class="mdi mdi-format-list-checks text-muted mr-1"></i> 
            Подзадач: <b>{{ active }}</b> / <small>{{ total }}</small>
        </span>
    </p>
</template>

<script>
export default {
    name: 'TaskCardStats',
    props: ['task'],
    data: () => {
        return {
            active: 0,
            finished: 0 
        }
    },
    computed: {
        total() {
            return this.active + this.finished
        }
    },
    mounted() {
        this.task.subtasks.forEach(item => {
            if(item.is_finished) this.finished++
            else this.active++
        })
    }
}
</script>

<style>

</style>