<template>
    <div class="card-widgets">
        <timer-badge :object="{ project_id: task.project_id, task_id: task.id }" :timers="timers"/>

        <router-link :to="{ name: 'TimerList', params: { project_id: task.project_id, task_id: task.id } }" title="Таймеры задачи" >
            <i class="mdi mdi-timetable"></i>
        </router-link>

        <a href="#" title="Завершить задачу" v-if="!task.is_finished"  @click.prevent="finishItem()">
            <i v-if="!loading.finish" class="mdi mdi-check-bold mr-1"></i>
            <i v-else class="spinner-border spinner-border-sm mr-1"></i>
        </a>

        <a href="#" v-if="task_description" title="Редактировать описание" @click.prevent="task_description.editValue()">
            <i class="mdi mdi-clipboard-text-outline mr-1"></i>
        </a>
        
        <a href="#" title="Удалить задачу" v-if="!task.is_deleted"  @click.prevent="deleteItem()">
            <i v-if="!loading.delete" class="mdi mdi-delete"></i>
            <i v-else class="spinner-border spinner-border-sm"></i>
        </a>
    </div>
</template>

<script>
import TimerBadge from '@/components/timer/TimerBadge.vue'

export default {
    name: 'TaskCardWidget',
    props: ['task', 'timers', 'task_description'],
    data: () => {
        return {
            loading: {
                finish: false,
                delete: false
            }
        }
    },
    methods: {
        async deleteItem() {
            if(!confirm(`Удалить ${this.task.title} ?`))
                return false

            this.loading.delete = true
            var [err, response] = await this.$orm.mutate({
                task: {
                    update: {
                        where: { id: { _eq: this.task.id }},
                        _set: { is_deleted: true }
                    }
                },
                project: {
                    update: {
                        where: { id: { _eq: this.task.project_id } },
                        _set: { updated_at_trigger: true },
                        fields: ['id']
                    }
                }
            })
            if(!err && !response.error)
                this.task.is_deleted = true

            this.loading.delete = false
        },
        async finishItem() {
            if(!confirm(`Завершить ${this.task.title} ?`))
                return false

            this.loading.finish = true
            var [err, response] = await this.$orm.mutate({
                task: {
                    update: {
                        where: { id: { _eq: this.task.id }},
                        _set: { is_finished: true, finished_by_id: this.$store.state.auth.user.id, finished_at: 'now()' }
                    }
                },
                project: {
                    update: {
                        where: { id: { _eq: this.task.project_id } },
                        _set: { updated_at_trigger: true },
                        fields: ['id']
                    }
                }
            })
            if(!err && !response.error)
                this.task.is_finished = true
            
            this.loading.finish = false
        }
    },
    components: {
        TimerBadge
    }
}
</script>

<style scoped>
.card-widgets {
    float: right;
    padding-left: 24px;
    padding-bottom: 24px;
     z-index: 2;
    position: relative;
}
.card-widgets i {
    padding: 0px 2px;
    transition: 150ms all ease;
}
.card-widgets i:hover {
    border-radius: 4px;
    background-color: rgba(0,0,0,.05);
}
.card-widgets a {
    vertical-align: middle;
}
</style>