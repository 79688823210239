<template>
	<div class="wrapper" :style="styles">
		<div class="editable-slot" v-if="!edit" @dblclick="editValue()" title="Двойное нажатие для редактирования">
			<slot></slot>
		</div>
		<div v-else class="editable-border">
            <vue-editor v-model="text" ref="editor"
                :editorToolbar='[ ["bold", "italic", "underline"], [{ list: "ordered" }, { list: "bullet" }], ["link"], ["code-block"], ["image"] ]'
				:editorOptions="editorOptions"
				@text-change="editorTextChange"
            ></vue-editor>
	
	        <button class="btn btn-outline-secondary btn-block btn-sm" type="button" @click="submitNewValue()">Сохранить</button>
		</div>
	</div>
</template>

<script>
import { VueEditor, Quill } from "vue2-editor";
import '@/plugins/quill'

import log from '@/plugins/log'
import { to } from '@/plugins/utils'

import QuillImageDropAndPaste from 'quill-image-drop-and-paste'
const ImageData = QuillImageDropAndPaste.ImageData
Quill.register('modules/imageDropAndPaste', QuillImageDropAndPaste)

export default {
	name: 'TextEditable',
	props: [ 'editable', 'styles', 'object' ],
	data: () => {
		return {
			edit: false,
			text: '',
			editor: null,
			images: [],
			imagesToDelete: []
		}
	},
	computed: {
		editorOptions() {
			return  {
				modules: {
					imageDropAndPaste: {
						handler: this.imageUploader
					},
					keyboard: {
						bindings: {
							ctrl_enter: {
								key: 13,
								ctrlKey: true,
								handler: (range) => {
									this.submitNewValue()
								}
							}
						}
					}

				}
			}
		}
	},
    beforeMount() {
        this.text = this.editable
	},
	methods: {
		editValue() {
			this.findDeletedImages()
			this.edit = true
			
			//custom image handler
			this.$nextTick(() => {
				const imageUploader = this.imageUploader
				this.$refs.editor.quill.focus()
				this.$refs.editor.quill.getModule('toolbar').addHandler('image', function(clicked) {
					if (clicked) {
						var fileInput = this.container.querySelector('input.ql-image[type=file]')
						if (fileInput == null) {
							fileInput = document.createElement('input')
							fileInput.setAttribute('type', 'file')
							fileInput.setAttribute('accept', 'image/png, image/gif, image/jpeg, image/bmp, image/x-icon')
							fileInput.classList.add('ql-image')
							fileInput.addEventListener('change', function(e) {
								var files = e.target.files, file
								if (files.length > 0) {
									file = files[0]
									var type = file.type
									var reader = new FileReader()
									reader.onload = (e) => {
										// handle the inserted image
										var dataUrl = e.target.result
										imageUploader(dataUrl, type, new ImageData(dataUrl, type))
										fileInput.value = ''
									}
									reader.readAsDataURL(file)
								}
							})
						}
						fileInput.click()
					}
				})
			})
		},
		submitNewValue() {            
			this.edit = false
			this.deleteImages()
			this.$emit('update:editable', this.text)
		},

		findDeletedImages() {
			let currentImages = []
			$($.parseHTML(this.text)).find('img.image-blot').each((i, el) => {
				let id = $(el).data('id')
				if(id) currentImages.push( $(el).data('id') )
			})

			this.images.forEach(id => {
				if( currentImages.indexOf(id) == -1 )
					this.imagesToDelete.push( id )
			})

			this.images = currentImages
		},

		async deleteImages() {
			if(!this.imagesToDelete) return;

			var [err, response] = await this.$orm.mutate({
				images: {
					update: {
						where: { id: { _in: this.imagesToDelete } },
						_set: { to_delete: true }
					}
				}
			})
			if(err) {
                log.error(`/views/portfolio/edit/${this.id}`, 'methods.deleteImages', '$http.request', err)
			}
			
			this.imagesToDelete = []
		},

		async imageUploader(imageDataUrl, type, imageData) {
			let miniImageData = await imageData.minify({
				maxWidth: 900,
				maxHeight: 900,
				quality: 0.9
			})

			var blob = miniImageData.toBlob()

			var formData = new FormData();
			formData.append("image", blob, 'tmp.jpg');

			var [err, response] = await to( this.$http.post(`/media/image/upload`, formData, {
				params: {
					type: 'object_description',
					object: this.object
				},
			}) )
			if(err) {
				log.error(`/views/portfolio/edit/${this.id}`, 'methods.imageUploader', '$http.request', err)
				$.NotificationApp.send("Ошибка", err.message, "top-right", "", "error")
				return resetUploader()
			}
			if(!response.data.success) {
				log.error(`/views/portfolio/edit/${this.id}`, 'methods.imageUploader', '$http.response', response)
				$.NotificationApp.send("Ошибка", response.data.error, "top-right", "", "error")
				return resetUploader()
			}
			log.debug('methods.imageUploader', '$http.request', response.data)

			let quill = this.$refs.editor.quill
			let cursorLocation = (quill.getSelection() || {}).index || quill.getLength()
			quill.insertEmbed(cursorLocation, "imageBlot", {
				src: this.$cdn({
					image: response.data.image,
					all: { w: 600 }
				}),
				data: {
					cdn: response.data.image.cdn_url,
					path: response.data.image.image_path,
					id: response.data.image.id
				}
			});
		},

		async editorTextChange(delta, oldDelta) {
			this.findDeletedImages()
		}
	},
	components: {
        VueEditor
	}
}
</script>

<style>
.editable-slot {
	width: 100%;
	cursor: text;
	position: relative;
}

.editable-border {
	border: 1px solid rgb(220, 222, 224);
	width: 100%;
	padding: 6px;
	border-radius: 0.25rem;
	-webkit-box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
    box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
}

.ql-toolbar {
    border: none !important;
}
.ql-container {
    border:none !important;
}
.ql-editor {
    min-height: 100px !important;
}

.wrapper img.image-blot {
	width: 100%;
	border: 1px solid var(--light);
	border-radius: 4px;
	-webkit-box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
	box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
	cursor: pointer;
}
</style>